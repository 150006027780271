<template>
  <a-card style="background-color: #fff;">
    <div :class="advanced ? 'search' : null" v-if="hasPermission('CUSTOMER_LIST')">
      <a-form layout="horizontal">
        <div :class="advanced ? null : 'fold'">
          <div class="cus-num">
              <div>
                <span>全部客户：</span>
                <span class="khtext">{{ totalNumber }}</span>
              </div>
              <div style="border-left: 1px solid rgba(85,66,246,0.06);">
                <span>今日新增：</span>
                <span class="khtext">{{ countCustomerToday }}</span>
              </div>
            </div>

          <a-row>
            <a-col :md="6" :sm="24" style="max-width: 400px;">
              <a-form-item label="客户名称" :labelCol="{ span: 5 }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-input v-model="queryData.name" placeholder="请输入客户名称" />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24" style="max-width: 400px;">
              <a-form-item label="客户经理" :labelCol="{ span: 5 }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-select allowClear v-model="queryData.staffIds" placeholder="请选择客户经理"
                :filter-option="filterOption" :default-value="null" mode="multiple" option-label-prop="label">
                <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in userData" :key="index">
                  {{ item.name }}
                </a-select-option>
              </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24" style="max-width: 400px;">
              <a-form-item label="企业类型" :labelCol="{ span: 5 }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-select allowClear v-model="queryData.enterpriseType" placeholder="请选择公司类型">
                  <a-select-option :value="index + 1" :label="item.name" v-for=" (item, index) in enterpriseType"
                    :key="index">
                    {{ item }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <!-- <a-col :md="6" :sm="24" style="max-width: 400px;">
              <a-form-item label="客户来源" :labelCol="{ span: 5 }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-select allowClear v-model="queryData.source" placeholder="请选择来源">
                  <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in soureList" :key="index">
                    {{ item.name }}
                  </a-select-option>
              </a-select>
              </a-form-item>
            </a-col> -->
          </a-row>

      

          <a-row>
            <a-col :md="6" :sm="24" style="max-width: 400px;">
              <a-form-item label="成交状态" :labelCol="{ span: 5 }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-select allowClear v-model="queryData.dealStatus" style="width: 100%" placeholder="请选择成交状态" option-label-prop="label">
                  <a-select-option :value="1" label="已成交">已成交</a-select-option>
                  <a-select-option :value="2" label="未成交">未成交</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24" style="max-width: 400px;">
              <a-form-item label="创建时间" :labelCol="{ span: 5 }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-range-picker allowClear :ranges="{'今天': [moment(), moment()], '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],'当月': [moment().startOf('month'), moment().endOf('month')],
  '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }" @change="dateChange" :value="dateValue"  />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24" style="max-width: 400px;">
              <a-form-item label="最后更新时间" :labelCol="{ span: 5 }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-range-picker allowClear :ranges="{'今天': [moment(), moment()], '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],'当月': [moment().startOf('month'), moment().endOf('month')],
  '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }" @change="dateModifyChange" :value="modifyValue"  />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24" >
              <a-button @click="reset">重置</a-button>
               <a-button type="primary" style="margin-left: 10px;" @click="search">查询</a-button>
               <a-button type="primary"  @click="exportChange(1)" style="margin-left: 10px;" >批量转移</a-button>
            </a-col>
          </a-row>

        </div>
      </a-form>

    </div>

    <!-- 转移 -->
    <a-modal title="转移联系人" :visible="exportVisible" :maskClosable="false" @ok="exportHandle"
      @cancel="exportVisible = false" :width="700">
      <a-select mode="multiple" v-model="queryData.customerManagerIdList" style="width: 100%" 
      :filter-option="(input, option) => { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0; }"
      placeholder="请选择归属人">
        <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in users" :key="index">
          {{ item.name }}
        </a-select-option>
      </a-select>
    </a-modal>

    <!-- 列表 -->
    <div style="margin-top: 0px;font-size: 15px;margin-left: 10px;">显示第 {{ total>0 ? 1 : 0 }} 至 {{ queryData.size > total ?  total : queryData.size }} 项结果，共 {{ total }} 项</div>
    <div style="margin-top: 20px;" v-if="hasPermission('CUSTOMER_LIST')">
      <a-table :columns="columns" :dataSource="dataSource" :loading="tabLoading" :pagination="false" bordered
        :row-selection="rowSelection" :expanded-row-keys.sync="expandedRowKeys" :scroll="{ x: 1500 }">
        <template slot="createTime" slot-scope="record">
          {{ formatDate(record) }}
        </template>
        <template slot="modifyTime" slot-scope="record">
          <span v-if="record">{{ formatDate(record) }}</span>
          <span v-else>-</span>
        </template>
        
        <!-- 客户经理 -->
        <template slot="staffList" slot-scope="record">
          <div v-for="item in record" :key="item.id" style="display: flex;align-items: center;cursor: pointer;justify-content: center;" @click="showModal(item.avatarPath)">
                <img v-if="item.avatarPath" style="width: 40px;height: 40px;border-radius: 100%;" :src="item.avatarPath" alt="">
                <img v-else style="width: 40px;height: 40px;border-radius: 100%;" src="https://file.youjiamall.cn/head-mr.png" alt="">
                <span>{{ item.name }}</span>
              </div>
        </template>
        
        <template slot="action" slot-scope="record">
          <div style="padding-left: 12px;">
              <div style="margin-bottom: 2px;">
                <a @click="editChange(record.id)">详情</a>
                <a style="margin:0 5px" @click="exportChange(0, record.id)">转移</a>
              </div>
            </div>
        </template>
      </a-table>
      <a-modal :visible="isImgShow" @cancel="handleCancel" :footer="null" >
        <img :src="showimgurl" style="width: 100%"  />
      </a-modal>
      <div class="page-box">
        <a-pagination :total="total" :current="queryData.page + 1" :page-size-options="pageSizeOptions" :pageSize="queryData.size" @change="handlePageChange" @showSizeChange="onShowSizeChange"
                      show-size-changer />
      </div>
    </div>

    <div style="text-align: center;font-size: 20px;margin-top: 100px;" v-else>
      <a-empty :description="false" />
      无权限查看
    </div>
  </a-card>
</template>

<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
import { mapGetters } from 'vuex'

const columns = [
  {
    title: '创建时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' },
    align: 'center',
    width: 220,
  },
  {
    title: '最后更新时间',
    dataIndex: 'modifyTime',
    scopedSlots: { customRender: 'modifyTime' },
    align: 'center',
    width: 220
  },
  {
    title: '公司名称',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' },
    align: 'center',
  },
  {
    title: '预算总额/年/万元',
    dataIndex: 'budgetAmount',
    align: 'center',
  },
  {
    title: '成交状态',
    dataIndex: 'dealStatusName',
    align: 'center',
  },
  {
    title: '客户经理',
    dataIndex: 'staffList',
    scopedSlots: { customRender: 'staffList' },
    align: 'center',
  },
  {
    title: '公司人数',
    dataIndex: 'scaleName',
    align: 'center',
    scopedSlots: { customRender: 'scaleName' },
    width:150
  },
  {
    title: '企业类型',
    dataIndex: 'enterpriseTypeName',
    align: 'center',
    scopedSlots: { customRender: 'enterpriseTypeName' },
    width:150
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'action' },
    align: 'center',
    fixed: 'right',
    width: 200
  }
]

export default {
  name: 'list',
  inject: ['reload'],
  data() {
    return {
      // 树形
      treeExpandedKeys: [],

      userInfo: { budgetAmount: 0, customerManagerIdList: [] },
      // 公司人数
      scaleList: [{ id: 1, name: '50人以下' }, { id: 2, name: '50-99人' }, { id: 3, name: '100-499人' }, { id: 4, name: '500-999人' }, { id: 5, name: '1000-9999人' }, { id: 6, name: '10000人以上' },],
      // 类型
      enterpriseType: ['医院', '街道', '医院', '综合', '咨询/设备/技术公司', '科技公司', '出版社', '酒店', '工程有限公司', '银行', '事业单位', '路桥建设', '税务局', '卫生局', '审计局', '进出口贸易', '园林局', '学校', '其他'],
      // 来源
      soureList: [{name:'天眼查',id:1},{name:'企查查',id:2}, {name:'陌生拜访',id:3}, {name:'朋友介绍',id:4}, {name:'客户转介绍',id:6},  {name:'其他',id:5}],
      // 联系人列表
      sfaContactsList: [{}],
      // 收货地址列表
      sfaContactAddressList: [{
        "consignee": "",
        "mobile": "",
        "provinceCode": "",
        "provinceName": "",
        "cityCode": "",
        "cityName": "",
        "countyCode": "",
        "countyName": "",
        "detailedAddress": ""
      }],
      // 公司地址
      customerAddressList: [{
        "provinceCode": '',
        "provinceName": "",
        "cityCode": '',
        "cityName": "",
        "countyCode": '',
        "countyName": "",
        "detailedAddress": ""
      }],
      selectedValue: '',

      countCustomerToday: 0,//今日新增
      totalNumber: 0,//全部
      expandedRowKeys: [],
      iptmd: 4,
      iptsm: 22,
      moment,
      customerData: [],
      advanced: true,
      columns: columns,
      dataSource: [],
      tabLoading: false,
      selectedRows: [],
      total: 0,
      // 筛选
      queryData: {
        page: 0,
        size: 10,
        retrievalType:3
      },
      currentPage: 1,
      // 创建表单
      form: {
        phone: [{ value: '' }],
        customerId: [],
        type: [],
        productPrice: '',
        productBudget: '',
        businessExpenses: '',
        purchaseQuantity: '',
        expectedProcurementTime: '',
        remarks: ''
      },
      // 新建方案弹窗
      labelCol: { span: 8 },
      wrapperCol: { span: 24 },
      roleList: ["未知", "总负责人", "重要决策人", "办事人员", "其他"],
      sexList: ["男", "女", "未知"],
      phone: [{ contactInformation: "", type: '1' }],
      wechat: [{ contactInformation: "", type: '4' }],
      zuoji: [{ contactInformation: "", type: '2' }],
      prov: [],
      city: [],
      area: [],
      visible: false,
      welfare: [],
      customerManagerIdList: [], //客户经理id
      users: [],

      exportVisible: false,

      selectedRowKeys: [], // Check here to configure the default column

      exportIdArr: [],
      describeText: "",


      gsAddress: [],//筛选公司地址
      gsCity: [],
      gsArea: [],
      userData: [],
      dateValue:"",
      pageSizeOptions: ['10', '30', '40', '50', '100', '500', '1000'],
      modifyValue:"",
      isImgShow:false

    }
  },
  computed: {
    ...mapGetters('account', ['user']),
    totalWelfareAmount() {
      return this.customerWelfareList.reduce((total, item) => {
        this.userInfo.budgetAmount = total + Number(item.welfareAmount);
        return total + Number(item.welfareAmount);
      }, 0);
    },
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        onSelection: this.onSelection,
      };
    },
    userPermissions() {
      return this.$store.state.account.user.authorityList;
    }
  },
  mounted() {
    this.getData();
    this.getUserList();
    request(process.env.VUE_APP_API_BASE_URL + 'sfa/welfare/list', 'get').then(res => {
      this.welfare = res.data.data;
      this.welfare.forEach(item => {
        this.customerWelfareList.forEach((son) => {
          if (item.id == son.welfareId) {
            son.describe = item.describe
            this.describeText = this.describeText + item.describe + '+'
          }
        })
      })
      this.describeText = this.describeText.replace(/\+$/, '')
    })

    //  客户经理 
    request(process.env.VUE_APP_API_BASE_URL + 'sfa/staff/list/1', 'get').then(res => {
      this.users = res.data.data;
      for (let i in this.users) {
        if (this.users[i].account == this.user.account) {
          this.userInfo.customerManagerIdList = [this.users[i].id]
          return false;
        }
      }
    })
  },
  methods: {
    search(){
      this.queryData.page = 0;
      this.getData()
    },
    showModal(text) {
      if(!text) {
        text = 'https://file.youjiamall.cn/head-mr.png'
      }
            this.showimgurl=text;
            this.isImgShow = true;
        },
        handleCancel() {
            this.isImgShow = false;
        },
    onShowSizeChange(current, pageSize) {
      this.queryData.size = pageSize;
      this.getData()
    },
    handlePageChange(page) {
      this.queryData.page = page - 1;
      this.currentPage = page;
      this.getData()
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    // 销售列表
    getUserList() {
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/users/list', 'post').then(res => {
        if (res.data.code == 200) {
          this.userData = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    // 选择筛选地址
    gsChange(e) {
      console.log(e, 'e')
      request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + e, 'get').then(res => {
        console.log(res, 'rese')
        // if (res.data.code == 200) {
        //   this.area = res.data.data;
        // } else {
        //   this.$message.error(res.data.message);
        // }
      })
    },
    // 重置搜索
    reset() {
      this.queryData = {
        page: 0,
        size: 10,
        retrievalType:3
      }
      this.dateValue = ""
      this.modifyValue = ""
      this.retrievalType = []
      this.getData()
    },
    hasPermission(permission) {
      return this.userPermissions.includes(permission);
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    //转移
    exportChange(type, e) {
      this.exportIdArr = [];
      if (type == 0) {
        this.exportIdArr[0] = e;
        this.exportVisible = true;
      } else {
        if (this.selectedRowKeys.length > 0) {
          this.exportVisible = true;

          this.selectedRowKeys.forEach(item => {
            this.exportIdArr.push(this.dataSource[item].id)
          })
        }
      }
    },
    exportHandle() {
      if (this.exportIdArr.length == 0) {
        this.$message.error('请选择客户');
        return false;
      }
      if (!this.queryData.customerManagerIdList) {
        this.$message.error('请选择转移人');
        return false;
      }
      let params = []
      this.exportIdArr.forEach(item => {
        params.push({ customerId: item, staffIdList: this.queryData.customerManagerIdList })
      })
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/shift', 'post', params).then(res => {
        if (res.data.code == 200) {
          this.$message.success('转移成功！')
          this.exportVisible = false;
          this.queryData.customerManagerIdList = [];
          this.getData()
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    checkEmptyProperty(obj) {
      return Object.values(obj).every(val => val !== '');
    },
    // 保存
    handleOk(e) {
      e.preventDefault()
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.phone[0].contactInformation == "" && this.wechat[0].contactInformation == "" && this.zuoji[0].contactInformation == "") {
            this.$message.error('请补全联系方式')
            return false;
          }

          // 校验福利金额
          let fare = true;
          this.customerWelfareList.forEach((obj) => {
            for (const key in obj) {
              if (Object.prototype.hasOwnProperty.call(obj, key)) {
                const value = obj[key];
                if (!value && value !== 0) {
                  fare = false;
                }
              }
            }
          });
          if (!fare) {
            this.$message.error('福利金额不可为空！');
            return false;
          }
          // 过滤空联系号码框
          let arr = this.phone.concat(this.wechat, this.zuoji);
          let mationList = arr.filter(obj => {
            for (let key in obj) {
              if (obj[key] === '') {
                return false; // 
              }
            }
            return true;
          });


          // 过滤空地址
          let sfaContactAddressList = this.sfaContactAddressList
          sfaContactAddressList = this.sfaContactAddressList.map(obj => {
            for (let key in obj) {
              if (obj[key] === '') {
                delete obj[key];
              }
            }
            return obj;
          }).filter(obj => Object.values(obj).some(value => value !== ''));

          let customerAddressList = this.customerAddressList;
          customerAddressList = this.customerAddressList.map(obj => {
            for (let key in obj) {
              if (obj[key] === '') {
                delete obj[key];
              }
            }
            return obj;
          }).filter(obj => Object.values(obj).some(value => value !== ''));
          let params = {
            "name": this.userInfo.gsname,
            "type": 1,
            "scale": this.userInfo.scale,
            "enterpriseType": this.userInfo.enterpriseType,
            "remarks": this.userInfo.remarks,
            "abbreviation": this.userInfo.abbreviation,
            "source": this.userInfo.source,
            "budgetAmount": this.userInfo.budgetAmount,
            "customerManagerIdList": this.userInfo.customerManagerIdList,
            "customerWelfareList": this.customerWelfareList,
            "customerAddressList": customerAddressList,
            "sfaContactsList": [{
              "name": this.userInfo.name,
              "role": this.userInfo.role,
              "sex": this.userInfo.sex,
              "birthday": this.userInfo.birthday,
              "sfaContactInformationList": mationList,
              "sfaContactAddressList": sfaContactAddressList
            }
            ]
          }
          request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/check', 'post', params).then(res => {
            if (res.data.data) {
              this.$message.warning(res.data.data)
              return false;
            }
            this.$message.loading();
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/save', 'post', params).then(res => {
              this.$message.destroy()
              if (res.data.code == 200) {
                this.$message.success('添加成功！');
                this.visible = false;
                this.getData();
                this.reload();
                this.userInfo = {}
              } else {
                this.$message.error(res.data.message);
              }
            })
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    // 添加地址
    addAddress(type, i) {
      if (type == 3 || type == 4) {
        if (type == 3) {
          this.customerAddressList.push({})
        } else {
          this.customerAddressList.splice(i, 1)
        }
        return false;
      }
      if (type == 0) {
        this.sfaContactAddressList.push({})
      } else {
        this.sfaContactAddressList.splice(i, 1)
      }
    },
    getCity() {
      request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/0', 'get').then(res => {
        if (res.data.code == 200) {
          this.prov = res.data.data;
          this.gsAddress = res.data.data;
        } else {
          this.$message.error(res.data.message)
        }
      })
    },

    // 格式化年月日
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();

      const formattedMonth = month < 10 ? '0' + month : month;
      const formattedDay = day < 10 ? '0' + day : day;
      const formattedHours = hours < 10 ? '0' + hours : hours;
      const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
      const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;

      let result = `${year}-${formattedMonth}-${formattedDay} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
      return result;
    },
    // 编辑
    editChange(id) {
      this.$router.push({
        path: '/sfa_customer/sfa_customer_details',
        query: { id: id }
      });
    },
    details(id) {
      this.$router.push({
        path: '/sfa_customer/Contact',
        query: { id: id }
      });
    },
    progDeal(id) {
      this.$router.push({
        path: '/sfa_customer/InvoiceList',
        query: { id: id }
      });
    },
    hisRecord(id){
      this.$router.push({
        path: '/sfa_telephoneRecord/sfa_history_record',
        query: { id: id }
      });
    },
    // 选择日期
    dateChange(date, dateString) {
      this.queryData.startTime = dateString[0].toString();
      this.queryData.endTime = dateString[1].toString();
      this.dateValue = date;
    },
    dateModifyChange(date, dateString){
      this.queryData.updateStartTime = dateString[0].toString();
      this.queryData.updateEndTime = dateString[1].toString();
      this.modifyValue = date;
    },
    resetQuery() {
      this.queryData = {
        page: 0,
        size: 10,
        name: '',
        phone: '',
        address: '',
        dateMax: '',
        dateMin: '',
        startTime: '',
        endTime: '',
        remarks: ''
      }
      this.getData()
    },
    birthdayChange(date, dateString) {
      this.userInfo.birthday = dateString
    },
    createChange(date, dateString) {
      this.userInfo.createTime = dateString
    },
    addIpt(i) {
      if (i == 0) {
        this.phone.push({ contactInformation: "", type: "1" });
      }
      if (i == 1) {
        this.wechat.push({ contactInformation: "", type: "4" });
      }
      if (i == 2) {
        this.zuoji.push({ contactInformation: "", type: "2" });
      }
    },
    delIpt(type, i) {
      if (type == 0) {
        this.phone.splice(i, 1)
      } else if (type == 1) {
        this.wechat.splice(i, 1)
      } else {
        this.zuoji.splice(i, 1)
      }
    },
    // 获取列表 
    getData() {
      this.tabLoading = true;
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/list', 'post', this.queryData).then(res => {
        let data = res.data.data;
        this.tabLoading = false;
        if (res.data.code == 200) {
          this.dataSource = data.data;
          this.queryData.page = data.page;
          this.queryData.size = data.size;
          this.total = data.total;
        } else {
          this.$message.error(res.data.message)
        }
      })

      // 查询当日新增
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/countCustomer', 'post', {retrievalType:3}).then(res => {
        let data = res.data.data;
        this.countCustomerToday = data.toDayNumber;
        this.totalNumber = data.totalNumber;

      })

    },
    // 是否确认删除
    delConfirm(e) {
      this.$message.loading();
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/' + e.id, 'delete').then(res => {
        this.$message.destroy();
        if (res.data.code == 200) {
          this.$message.success('删除成功')
          this.getData()
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.search {
  // margin-bottom: 54px;
}

.fold {
  width: calc(100% - 216px);
  display: inline-block
}

.operator {
  margin-bottom: 18px;
}

.cre-btn {
  width: 100%;
  height: 64px;
  line-height: 64px;
  background: #EEECFE;
  border-radius: 4px;
  border: 1px dashed #5542F6;
  cursor: pointer;
  font-size: 16px;
  font-family: PingFangHK-Regular, PingFangHK;
  font-weight: 400;
  color: #5542F6;
}

.btn-box button {
  width: 106px;
  height: 36px;
  margin-left: 10px;
}



.page-box {
  display: flex;
  justify-content: right;
  padding-right: 46px;
  margin-top: 10px;
}

::v-deep .ant-card-body {
  padding-top: 0;
}



.top-text p {
  height: 10px;
}

.top-text div {
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-right: 20px;
  padding: 2px 20px;
  box-sizing: border-box;
}

.top-form {
  box-sizing: border-box;
}

.top-form .ant-form-item {
  margin-right: 20px !important;
}

::v-deep .top-form .ant-col-6 {
  width: auto !important;
}


::v-deep .ant-col-6 {
  width: auto !important;
}



.cus-num {
  display:flex;
  background: #F4F0FF;
  height: 50px;
  width: 400px;
  border-radius: 10px;
  padding: 0 14px;
  box-sizing: border-box;
  align-items: center;
  margin: 10px 0;
}
.cus-num div{
  flex: 1;
  text-align: center;
}

.khtext {
  font-family: DINAlternate, DINAlternate;
font-weight: bold;
font-size: 20px;
color: #5542F6;
text-align: left;
font-style: normal;
text-transform: none;
}
</style>
